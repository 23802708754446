<template lang="pug">
InputContainer.select-container(
  :validationMessages="validationMessages"
  :validationState="validationState"
  :disabled="disabled || forceDisabled"
  :label="label"
  :class="{ selected: modelValue, forceDisabled: forceDisabled }"
  @mousedown.native="onContainerMouseDown"
)
  input(
    :tabindex="tabindex"
    ref="input"
    v-model="query"
    :placeholder="selectedLabel ? null : placeholder"
    :disabled="disabled"
    @focus="onFocus"
    @blur="onBlur"
    @input="onQueryChange"
    @keydown="navigate"
  )
  button.btn-clean.btn-reset(
    type="button" v-if="((modelValue && selectedLabel) || query) && !forceDisabled" @click="reset"
  ): i.icon-close

  .selected-value(:class="{ typing: active }")
    b {{selectedGroup}}
    |{{ selectedLabel }}

  .list(:class="{ active, 'not-found': nothingFound }" ref="list"): ul
    li.message {{ $t('selectItemNotFound') }}

    li(
      v-for="option in filteredOptions"
      :class="{ selected: option.id === modelValue }"
      v-if="!isGrouped"
    )
      button.list-item(
        type="button" @click="onChange(option)" :class="{ focus: focusedId === option.id }"
      ) {{option.label}}

    template(
      v-for="(list, groupName) of filteredOptions"
      v-if="isGrouped"
    )
      li.group-name {{groupName}}
      li(v-for="option in list" :class="{ selected: option.id === modelValue }")
        button.list-item(
          type="button" @click="onChange(option)" :class="{ focus: focusedId === option.id }"
        ) {{option.label}}
</template>

<script>
import InputContainer from './InputContainer.vue';

export default {
  name: 'Select',

  emits: ['update:modelValue'],

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    label: String,
    placeholder: String,
    tabindex: [String, Number],
    options: [Array, Object],
    disabled: Boolean,
    forceDisabled: Boolean,
    validationMessages: Object,
    validationState: Object,
    getGroupValue: Function || null,
    getLabelValue: Function || null,
    modelValue: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      active: false,
      query: null,
      isGrouped: !Array.isArray(this.options),
      focusedIdIndex: null,
      focusedId: null,
    };
  },

  methods: {
    onChange({ id }) {
      console.log('on change', id);
      if (!id) return;
      this.query = null;
      this.validationState?.$touch();
      this.$emit('update:modelValue', id);
      console.log('update:modelValue', id);
      setTimeout(() => this.$refs.input?.blur());
    },

    reset() {
      if (this.query && !this.modelValue) {
        this.query = null;
        return;
      }
      this.$emit('update:modelValue', null);
      this.validationState?.$reset();
    },

    onQueryChange() {
      if (this.modelValue) {
        this.reset();
      }

      if (!this.filteredIds.includes(this.focusedId)) {
        this.focusedIdIndex = null;
        this.focusedId = null;
        this.$nextTick(() => {
          this.$refs.list.scrollTop = 0;
        });
      }
    },

    onFocus() {
      if (!this.forceDisabled) {
        this.active = true;
      }
    },

    onBlur() {
      this.active = false;
      this.focusedIdIndex = null;
      this.focusedId = null;
    },

    onContainerMouseDown() {
      if (!this.forceDisabled) {
        setTimeout(() => this.$refs.input.focus());
      }
    },

    navigate(e) {
      if (this.forceDisabled) {
        return;
      }

      const mapping = {
        40: 'down',
        38: 'up',
        13: 'enter',
      };
      const key = mapping[e.which];

      if (key === 'enter') {
        this.onChange({ id: this.focusedId });
        e.preventDefault();
        return;
      }

      if (!key) return;
      e.preventDefault();
      if (this.focusedIdIndex !== null) {
        const maxIndex = this.filteredIds.length - 1;
        const currentIndex = this.focusedIdIndex;
        let newIndex = currentIndex + (key === 'down' ? 1 : -1);
        if (newIndex < 0) newIndex = 0;
        if (newIndex > maxIndex) newIndex = maxIndex;
        this.focusedIdIndex = newIndex;
      } else if (this.modelValue) {
        this.focusedIdIndex = this.filteredIds.findIndex((v) => v === this.modelValue);
      } else {
        this.focusedIdIndex = 0;
      }

      this.focusedId = this.filteredIds?.[this.focusedIdIndex];

      this.$nextTick(this.scrollToFocusedItem);
    },

    scrollToFocusedItem() {
      const { list } = this.$refs;
      const focusedItem = list.querySelector('.focus');
      if (!focusedItem) return;

      const itemBottomPos = focusedItem.offsetTop + focusedItem.offsetHeight;
      const viewportBottomPos = list.scrollTop + list.offsetHeight;

      if (itemBottomPos < viewportBottomPos && focusedItem.offsetTop > list.scrollTop) {
        return;
      }

      list.scrollTop = focusedItem.offsetTop - 50;
    },
  },

  computed: {
    filteredIds() {
      if (this.isGrouped) {
        return Object.values(this.filteredOptions).flat().map(({ id }) => id);
      }

      return this.filteredOptions.map(({ id }) => id);
    },

    filteredOptions() {
      if (!this.query) return this.options;
      const q = this.query.toLowerCase().trim();
      if (this.isGrouped) {
        return Object.entries(this.options)
          .filter(([groupName, items]) => {
            const isGroupMatch = groupName.toLowerCase()
              .includes(q);
            const isItemMatch = items
              .find(({ label }) => label.toLowerCase().includes(q));
            return isGroupMatch || isItemMatch;
          })
          .reduce((acc, [groupName, list]) => {
            const isGroupMatch = groupName.toLowerCase().includes(q);
            return {
              ...acc,
              [groupName]:
                isGroupMatch ? list : list.filter(({ label }) => label.toLowerCase().includes(q)),
            };
          }, {});
      }

      return this.options.filter(({ label }) => (
        label.toLowerCase().includes(q)
      ));
    },

    nothingFound() {
      return !this.filteredOptions || !Object.keys(this.filteredOptions).length;
    },

    selectedGroup() {
      if (!this.isGrouped) return null;
      if (!this.modelValue) return null;

      let found = null;

      // Если options массив (ожидается [{ label: 'name', id: 1 }])
      if (this.options?.length) {
        found = this.options?.find(({ id }) => id === this.modelValue);
      } else {
        found = Object.entries(this.options)
          .find(([, list]) => list.find(({ id }) => id === this.modelValue))?.[0];
        return found ? `${found}: ` : null;
      }

      if (found) {
        // call props.getGroupValue(found)

        if (this.getGroupValue) {
          return this.getGroupValue(found);
        }
      }
      return null;

      // if (!this.isGrouped) return null;
      // if (!this.modelValue) return null;
      //
      // const found = Object.entries(this.options)
      //   .find(([, list]) => list.find(({ id }) => id === this.modelValue))?.[0];
      // return found ? `${found}: ` : null;
    },

    selectedLabel() {
      if (!this.modelValue) return null;

      if (this.isGrouped) {
        const found = Object.values(this.options)
          .flat()
          .find(({ id }) => id === this.modelValue);
        return found?.label || null;
      }

      const found = this.options.find(({ id }) => id === this.modelValue);
      return found?.label || null;
      // if (!this.modelValue) return null;
      //
      // if (this.isGrouped) {
      //   const found = Object.values(this.options)
      //     .flat()
      //     .find(({ id }) => id === this.modelValue);
      //   return found?.label || null;
      // }
      //
      // const found = this.options.find(({ id }) => id === this.modelValue);
      // return found?.label || null;
    },
  },

  components: {
    InputContainer,
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

.select-container
  &.selected
    padding-right: 50px
  &.forceDisabled
    background: white

input
  border: none
  display: block
  width: 100%
  outline: none
  padding: 0
  background-color: transparent
  font-size: $font-size
  color: $color-text
  &::-ms-clear
    display: none
  &:-ms-input-placeholder
    color: $color-text
    opacity: 0.3

.selected-value
  font-size: $font-size
  color: $color-text
  user-select: none
  margin-top: -19px
  margin-bottom: -2px

  &.typing
    opacity: .3

.list
  overflow: hidden
  overflow-y: auto
  position: absolute
  width: 100%
  left: 0
  top: calc(100% + 8px)
  background-color: $color-white
  z-index: 10

  m = 1;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034/m),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048/m),
  0 12.5px 10px rgba(0, 0, 0, 0.06/m),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072/m)

  border-radius: 4px
  max-height: 0
  trans('max-height')
  &.active
    max-height: 200px

  li.message
    display: none
  &.not-found
    &.active
      max-height: 50px
    li.message
      display: block

li
  &.selected
    button
      background-color: rgba($color-note, .5) !important
      color: $color-white
      font-weight: 700

.group-name
  padding: 16px 16px
  font-size: 14px
  color: $color-text
  font-weight: 700

.list-item, .message
  display: block
  width: 100%
  border: none
  text-align: left
  background: none
  outline: none
  padding: 16px 16px
  font-size: 14px

.list-item
  cursor: pointer
  &:hover
    background-color: rgba($color-border, .3)
  &:active
    background-color: rgba($color-border, .5)
  &.focus
    background-color: rgba($color-border, .3)
    outline: 1px solid rgba($color-base, .5)

.message
  text-align: center

.btn-reset
  position: absolute
  right: 0
  font-size: 16px
  top: 0
  bottom: 0
  margin: auto
  padding: 16px
  height: 48px
  z-index: 5
  opacity: .5
  trans('opacity')
  &:hover
    opacity: 1
</style>
