const config = {
  development: {
    API: `http://${window.location.hostname}:3000`,
    PGMerchant: '90000012',
    PGMerchantFPS: '90000019',
    payment: {
      min: 10,
      max: 15000,
    },
    paymentMethods: ['card', 'apple', 'google', 'qr'],
    googlePay: {
      gateway: 'best2pay',
      gatewayMerchantId: '2570',
      merchantName: 'АО «АБ «РОССИЯ»',
      merchantId: 'TEST',
      environment: 'TEST',
    },
    bannerMessage: '',
    registerReceipt: true,
  },
  stage: {
    API: `${window.location.origin}/api`,
    PGMerchant: '90000012',
    PGMerchantFPS: '90000019',
    payment: {
      min: 10,
      max: 15000,
    },
    paymentMethods: ['card', 'qr'],
    googlePay: {
      gateway: 'best2pay',
      gatewayMerchantId: '2570',
      merchantName: 'АО «АБ «РОССИЯ»',
      merchantId: 'TEST',
      environment: 'TEST',
    },
    registerReceipt: true,
  },
  test: {
    API: 'http://api.com',
    PGMerchant: '90000012',
    PGMerchantFPS: '90000019',
    payment: {
      min: 1,
      max: 15000,
    },
    paymentMethods: ['card', 'qr'],
    googlePay: {
      gateway: 'best2pay',
      gatewayMerchantId: '2570',
      merchantName: 'АО «АБ «РОССИЯ»',
      merchantId: 'TEST',
      environment: 'TEST',
    },
    registerReceipt: true,
  },
  dev: {
    API: `${window.location.origin}/api`,
    PGMerchant: '90000012',
    PGMerchantFPS: '90000019',
    payment: {
      min: 1,
      max: 15000,
    },
    paymentMethods: ['card', 'apple', 'google', 'qr'],
    googlePay: {
      gateway: 'best2pay',
      gatewayMerchantId: '2570',
      merchantName: 'АО «АБ «РОССИЯ»',
      merchantId: 'TEST',
      environment: 'TEST',
    },
    registerReceipt: true,
  },
  preprod: {
    API: `${window.location.origin}/api`,
    PGMerchant: '90000012',
    PGMerchantFPS: '90000019',
    payment: {
      min: 1,
      max: 15000,
    },
    paymentMethods: ['qr', 'card', 'apple', 'google', 'qr'],
    googlePay: {
      gateway: 'best2pay',
      gatewayMerchantId: '2570',
      merchantName: 'АО «АБ «РОССИЯ»',
      merchantId: 'TEST',
      environment: 'TEST',
    },
    registerReceipt: true,
  },
  production: {
    API: `${window.location.origin}/api`,
    PGMerchant: '90000001',
    PGMerchantFPS: '90000018',
    payment: {
      min: 10,
      max: 60000,
    },
    paymentMethods: ['card', 'qr'],
    googlePay: {
      gateway: 'best2pay',
      gatewayMerchantId: 'abrussia',
      merchantName: 'abrussia',
      merchantId: 'BCR2DN6T6PVYXZQH',
      environment: 'PRODUCTION',
    },
    registerReceipt: true,
  },
};

export default config[process.env.CONFIG_ENV || process.env.NODE_ENV];
