<template lang="pug">
form(@submit.prevent="onFormData")
  SystemMessage.system-message(
    v-if="status === 'error' && !error.fromAPI"
    :title="$t('serviceUnavailable')"
    :desc="$t('tryAgainLater')"
  )
  .row.row-organization
    .col
      Select(
        :forceDisabled="forceDisabled"
        ref="select"
        tabindex="2"
        :label="$t('organizationLabel')"
        :placeholder="$t('regionPlaceholder')"
        :options="getGroupedOrgsByRegion"
        :disabled="status === 'running' || inactive"
        :get-group-value="getRegionName"
        @update:modelValue="onOrgSelect"
        :model-value="org"
      )
      Select(
        v-if="isDepartments"
        ref="select2"
        tabindex="2"
        v-model="formData.department" :validation-state="$v.formData.department" :validation-messages="validationMessages.department"
        :label="'Департамент'"
        :placeholder="'Выберите подразделение'"
        :options="departments"
        @update:modelValue="onDepartmentSelect"
        :disabled="status === 'running' || inactive"
      )
    .col.account-note(v-if="!targetOrg")
      i.icon-letter
      p.description {{$t('organizationSelectorNote')}}
    .col.account-note(v-if="targetOrg")
      i.icon-letter

  .row.row-account
    .col
      Input(
        tabindex="3"
        v-model="formData.acc" :validation-state="$v.formData.acc" :validation-messages="validationMessages.acc"
        :label="$t('accountLabel')"
        placeholder="10000000"
        :disabled="status === 'running' || inactive"
        @update:modelValue="loadAccountTask.clear()"
        clearable="true"
      )
    .col.account-note
      p.description {{$t('accountSelectorNote')}}

  .row
    .col
      Button(
        :loading="status === 'running'" type="submit" v-if="!inactive" tabindex="5"
      ) {{$t('continue')}}
    .col
      a.link(
        download
        :href="docUrl"
        style="text-decoration:underline;"
      ) Порядок освобождения плательщика от комиссии при оплате ЖКУ

  div(tabindex="6" onFocus="document.querySelector('[tabindex=\"2\"]').focus()")
</template>

<script>
/* eslint-disable */
import api from '@/libs/api';
import validation from './validation';
import SystemMessage from '#c/uikit/SystemMessage.vue';
import Input from '#form/Input.vue';
import Select from '#form/Select.vue';
import Button from '#c/uikit/Button.vue';

export default {
  name: 'AccountSelectorForm',

  mixins: [validation],

  props: {
    storage: null,
    inactive: Boolean,
  },

  created() {
    this.syncWithStorage();
  },

  mounted() {
    console.log('form created', this.props);
    document.querySelector('[tabindex="1"]').focus();
  },

  setup() {
    return {
      loadAccountTask: api.createTask('ReqCheckAbonent'),
    };
  },

  data() {
    return {
      docUrl: '/poryadok_osvobozhdeniya_platelchika_ot_komissii_pri_oplate_ZHKU.pdf',
      targetOrg: null,
      formData: {
        org: null,
        acc: null,
        region: null,
        department: null,
        needDepartment: false,
      },
    };
  },

  methods: {
    onOrgSelect(data) {
      console.log(data);
      this.$store.commit('set_selected_organization_id', data);
    },
    onOrgSelectByCode(data) {
      this.$store.commit('set_selected_organization_id_by_code', data);
    },
    onDepartmentSelect(data) {
      this.$store.commit('set_selected_department_id', data);
    },
    getRegionName(organization) {
      console.log('getRegionName', organization);
      const id = organization.region_id;
      const found_region = this.$store.state.regions.find((region) => region.id === id);

      if (found_region) {
        return `${found_region.name} : `;
      }

      return '';
    },
    async onFormData() {
      if (!this.formData.acc && !this.formData.org) {
        return;
      }
      console.log('onFormData', this.formData);
      const YYYY = new Date().getFullYear();
      const MM = `0${new Date().getMonth() + 1}`.slice(-2);

      const orgId = this.$store.getters.selected_org.code;

      const response = await api.performTask(this.loadAccountTask, {
        TypePaymentCode: orgId,
        AbonentID: String(this.formData.acc).trim(),
        Month: `${YYYY}${MM}`,
      });

      if (!response) return;

      const { data: accountDetails } = response;

      this.storage.set('account', {
        org: this.formData.org,
        acc: this.formData.acc,
      });

      const path = {
        name: 'review',
        query: {
          org: orgId,
          acc: this.formData.acc,
        },
      };

      const { targetOrg } = this.$route.query;
      if (targetOrg) {
        path.query.targetOrg = targetOrg;
      }

      this.$store.commit('accountDetails', accountDetails);
      await this.$router.push(path);
    },

    syncWithStorage() {
      const { targetOrg: _targetOrg } = this.$route.query || null;
      if (_targetOrg) {
        const target = this.$store.state.organizations.find((org) => org.code === _targetOrg);

        if (target) {
          this.targetOrg = target;
          this.formData.org = target.code;
          this.$store.commit('set_selected_organization_id_by_code', target.code);
          return;
        }
      }

      const { org, acc } = this.$route.query || {};
      if (org) {
        this.onOrgSelectByCode(org);
        console.log('set org', org, acc);

        this.formData.org = org;
        this.formData.acc = acc;
        return;
      }
      const storageData = this.storage.get('account');
      if (!storageData?.org) return;

      // this.onOrgSelect(storageData.org);
      this.formData.org = storageData.org;
      this.formData.acc = storageData.acc;
    },
  },

  computed: {
    status() {
      return this.loadAccountTask?.last?.status;
    },

    error() {
      return this.loadAccountTask?.last?.error || {};
    },

    isOrgSelected() {
      return this.$store.getters.selected_org;
    },

    isDepartments() {
      return this.isOrgSelected && this.isOrgSelected.departments.length;
    },

    mappedRegions() {
      return [];
    },

    getGroupedOrgsByRegion() {
      return this.$store.state.organizationsByRegion;
    },

    organizations() {
      return this.$store.state.organizations;
    },

    departments() {
      return this.$store.getters.selected_org
        .departments.map((dep) => ({ ...dep, label: dep.name }));
    },

    org() {
      return this.$store.getters.selected_org?.id;
    },

    forceDisabled() {
      return !!this.targetOrg;
    },
  },

  components: {
    SystemMessage,
    Input,
    Select,
    Button,
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

$columns-gap = 70px

.system-message
  margin-bottom: 32px

.row
  display: flex
  margin-bottom: 42px

  @media(max-width: 767px)
    flex-direction: column
    margin-bottom: 8px

.col
  width: 50%
  padding: 0 ($columns-gap / 2)
  &:first-child
    padding-left: 0
  &:last-child
    padding-right: 0

  @media(max-width: 767px)
    width: 100%
    padding: 0

.description
  color: $color-label
  line-height: 1.7
  @media(max-width: 767px)
    font-size: 14px
    line-height: 1.3
    margin-top: 8px
    margin-bottom: 16px
    color: $color-note
    background-color: rgba(@color, .15)
    border-radius: 4px
    padding: 8px

.account-note
  position: relative
  i
    color: rgba($color-label, .1)
    font-size: 320px
    position: absolute
    width: @font-size
    height: @font-size
    right: 0
    left: 0
    margin: auto
    top: -40px
    pointer-events: none

    @media(max-width: 767px)
      display: none

.btn
  backface-visibility: hidden
  @media(max-width: 767px)
    display: block
    width: 100%
    position: sticky
    bottom: 10px
    z-index: 10
</style>
