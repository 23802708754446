// import scrollTo from 'scroll-to';
import {
  createMessages,
  createRules,
} from '@/libs/validation_rules';

/*
Requirements:

1) @submit.prevent="onSubmit" on component form element
2) Use formData prop to store form data if you want to use validation-model attribute
3) Create onFormData method

*/

const createRulesData = (rules, context) => {
  if (typeof rules === 'function') {
    return rules(context);
  }
  return rules;
};

const setFieldFocus = (el) => {
  const input = el.querySelector('input')
    || el.querySelector('textarea')
    || el.querySelector('select');
  input.focus();
};

const focusErrorField = () => {
  const invalidInputContainer = document.querySelector('.input-container-invalid');
  if (!invalidInputContainer) return;
  setFieldFocus(invalidInputContainer);
  // scrollTo(invalidInputContainer, 300, { offset: -20 });
  // if (!this.$store.state.activeModalName) {
  //   this.$scrollTo(firstErrorFieldContainer, 300, { offset: -20 });
  // }
};

export default (rules) => ({
  data() {
    return {
      validationMessages: createMessages(createRulesData(rules, this)),
    };
  },

  // @TODO remove when @vuelidate/core will be updated from 2.0.0-alpha.8
  beforeDestroy() {
    const options = this.$options;
    if (options.computed.$v) {
      delete options.computed.$v;
    }
  },

  validations() {
    return createRules(createRulesData(rules, this));
  },

  methods: {
    onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$nextTick(focusErrorField);
        return;
      }

      if (this.onFormData) {
        this.onFormData();
      } else {
        throw new Error('You must create "onFormData" method in your component');
      }
    },
  },
});
