import { required } from '@vuelidate/validators';
import CreateValidationMixin from '@/mixins/validation';

function getValidations(self) {
  const data = {
    org: {
      required: {
        rule: required,
        message: () => self.$t('organizationRequired'),
      },
    },
    acc: {
      required: {
        rule: required,
        message: () => self.$t('accountRequired'),
      },
      check: {
        rule: () => !self.error?.fromAPI,
        // message: () => self.error?.Message,
        message: () => self.$t('accountNotFound'),
      },
    },
  };

  if (self.isDepartments) {
    data.department = {
      required: {
        rule: required,
        message: () => self.$t('departmentRequired'),
      },
    };
  }

  return {
    formData: data,
  };
}

export default CreateValidationMixin((self) => getValidations(self));
