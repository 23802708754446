<template>
  <div id="app" tabindex="1">
    <div class="app-wrapper">
      <div id="content">
        <Banner
          v-if="$route.name === 'home' && config.bannerMessage"
          :message="config.bannerMessage"
        />
        <main>
          <router-view></router-view>
        </main>
        <Footer />
      </div>
    </div>
  </div>
</template>

<style lang="stylus">

</style>

<script>
import api from '@/libs/api';
import config from '@/config/client';
import Footer from '#c/layout/Footer.vue';
import Banner from '#c/layout/Banner.vue';

export default {
  name: 'App',

  data() {
    return {
      config,
    };
  },

  setup() {
    return {
      loadRegions: api.createTask('APIRegions'),
      loadOrganizations: api.createTask('APIOrganizations'),
    };
  },

  created() {
    this.loadData();
  },

  methods: {
    async loadData() {
      const regions = await api.performTask(this.loadRegions);
      const orgs = await api.performTask(this.loadOrganizations);

      this.$store.commit('set_regions', regions.data.regions);
      this.$store.commit('set_organizations', orgs.data.organizations);
      this.$store.commit('set_grouped_organizations_region');
    },
  },

  components: {
    Footer,
    Banner,
  },
};
</script>
