export default ({
  data, email, total, formData,
}) => ({
  TypePaymentCode: data.TypePaymentCode,
  AbonentID: data.AbonentID,
  Recurring: 0,
  Month: data.Month,
  OrderSumm: total,
  Cashless: 0,
  Email: email,
  Phone: '',
  SendReceipt: 0,
  Payments: {
    Payment: data.charges
      .filter(({ id }) => Number(String(formData.pay[id]).replace(',', '.')))
      .map((charge) => {
        const CounterCharges = {
          CounterCharge: data.counters
            .filter(({ chargeId }) => chargeId === charge.id)
            .filter(({ id }) => Number(formData.counter[id]))
            .map((counter) => ({
              IDCounter: counter.IDCounter,
              Counter: counter.Counter,
              Measure: counter.Measure,
              Normative: counter.Normative,
              PrevCounter: counter.PrevCounter,
              CurrCounter: formData.counter[counter.id],
            })),
        };

        return {
          Type: CounterCharges.CounterCharge.length ? 1 : 0,
          IDpu: charge.IDpu || data.TypePaymentCode,
          Service: charge.Service,
          LsPU: charge.LsPU || data.AbonentID,
          ToPay: Number(String(formData.pay[charge.id]).replace(',', '.')),
          SummFee: formData.fee[charge.id],
          SummFeeAgent: 0,
          CounterCharges,
        };
      }),
  },
});
