<script>
import * as storage from '@/libs/storage';
import api from '@/libs/api';
import AccountSelectorForm from '#c/account_selector/Form.vue';
import AlertModal from '#c/uikit/AlertModal/AlertModal.vue';

export default {
  name: 'Home',

  created() {
    this.parseHashCode();
  },

  data() {
    return {
      storage,

      modal: {
        visible: false,
        type: null,
        title: null,
        description: null,
        buttonText: null,
        closeHandler: () => {},
      },
    };
  },

  computed: {
    organizations() {
      return this.$store.state.organizations;
    },
    regions() {
      return this.$store.state.regions;
    },
    selectedOrganization() {
      return this.$store.state.selected_organization_id;
    },
    loaded() {
      return this.$store.getters.loaded_orgs;
    },
  },

  methods: {
    // https://jira.abr-region.ru/browse/UKPCDEV-60
    async parseHashCode() {
      const { code: hashCode } = this.$route.query;
      if (!hashCode) return;

      try {
        // Используется прямой вызов метода `api.proxy`,
        // потому что дефолтный метод создания тасков
        // с использованием ключа маппера (api.createTask('MAPPER_KEY'))
        // не дает возможности передавать динамические аргументы
        const response = await api.proxy({
          method: 'get',
          endpoint: `/qrInfo/findOne/${hashCode}`,
        });

        const {
          connected,
          organization_code,
          account,
        } = response.data.qrInfo;

        // Данные заполнены
        if (organization_code && account) {
          if (connected) {
            // Счетчик активирован. Редирект на экран оплат
            this.$router.replace({
              name: 'review',
              query: {
                org: organization_code,
                acc: account,
              },
            });
          } else {
            // Счетчик не активирован. Редирект на экран активации счетчика
            this.$router.replace({
              name: 'activate',
              query: {
                code: hashCode,
                org: organization_code,
                acc: account,
              },
            });
          }
        } else {
          // Данные не заполнены. Редирект на экран подключения счетчика
          this.$router.replace({
            name: 'add',
            query: {
              code: hashCode,
            },
          });
        }
      } catch (error) {
        // Данные невалидны. Сброс URL параметров
        this.$router.replace({ name: 'home' });

        if (error?.message) {
          this.showAlert({
            type: 'negative',
            title: 'Ошибка!',
            description: error.message,
            buttonText: 'Закрыть',
            closeHandler: this.hideAlert,
          });
        }
      }
    },

    showAlert(options) {
      this.modal.visible = true;
      this.modal.type = options.type;
      this.modal.title = options.title;
      this.modal.description = options.description;
      this.modal.buttonText = options.buttonText;
      this.modal.closeHandler = options.closeHandler;
    },

    hideAlert() {
      this.modal.visible = false;
      this.modal.type = false;
      this.modal.title = false;
      this.modal.description = false;
      this.modal.buttonText = false;
      this.modal.closeHandler = () => {};
    },
  },

  components: {
    AccountSelectorForm,
    AlertModal,
  },
};
</script>

<template>
  <div id="account-selector">
    <h1>{{ $t('payment') }}</h1>
    <AccountSelectorForm
      v-if="loaded"
      :organizations="organizations"
      :regions="regions"
      :storage="storage"
    />
    <AlertModal
      v-if="modal.visible"
      :type="modal.type"
      :title="modal.title"
      :description="modal.description"
      :button-text="modal.buttonText"
      @close="modal.closeHandler"
    />
  </div>
</template>

<style scoped lang="stylus">
@import '~#a/style/config'

h1
  margin-bottom: 32px
  @media (max-width: 767px)
    margin-bottom: (@margin-bottom / 1.5)
</style>
