<template lang="pug">
.banner
  i.icon-alert
  p.message(v-html="message")
</template>

<script>
export default {
  name: 'Banner',

  props: {
    message: String,
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

.banner
  position: relative
  width: 100%
  padding: 16px 32px
  border-radius: 8px 8px 0 0
  background: linear-gradient(90deg, rgba(#c91f3f, .7),rgba(#e84634, .7))
  @media(max-width: 767px)
    padding: 16px 16px

.icon-alert
  top: 0
  bottom: 0
  margin: auto
  display: block
  height: 20px
  position: absolute
  font-size: 16px

.message
  opacity: .5
  line-height: 16px;
  font-size: 16px
  margin-left: 25px
  white-space: break-spaces
  @media(max-width: 767px)
    font-size: 10px
  @media(max-width: 374px)
    font-size: 9px
</style>
