export default {
  card(self, { order, invoice }) {
    const { org, acc } = self.$route.query;

    return {
      InvoiceUID: invoice.data.UID,
      org,
      acc,
      email: self.email,
      month: self.data.Month,
      OrderID: order.data.Order.OrderID,
      SessionID: order.data.Order.SessionID,
      type: 'card',
    };
  },

  qr(self, { qrData, invoice, order }) {
    const { org, acc } = self.$route.query;
    return {
      InvoiceUID: invoice.data.UID,
      org,
      acc,
      email: self.email,
      month: self.data.Month,
      OrderID: order.data.Order.OrderID,
      SessionID: order.data.Order.SessionID,
      qr: qrData.data.Payload,
      type: 'qr',
    };
  },

  apple(self, { order, invoice }) {
    return {
      type: 'apple',
      order_id: order.order_id,
      InvoiceUID: invoice.UID,
      org: self.data.TypePaymentCode,
      acc: self.data.AbonentID,
      email: self.email,
      month: self.data.Month,
    };
  },

  google(self, { order, invoice }) {
    return {
      type: 'google',
      order_id: order.id,
      InvoiceUID: invoice.UID,
      org: self.data.TypePaymentCode,
      acc: self.data.AbonentID,
      email: self.email,
      month: self.data.Month,
    };
  },
};
