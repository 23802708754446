export default (amount) => ({
  requiredShippingContactFields: ['email'],
  countryCode: 'RU',
  currencyCode: 'RUB',
  supportedNetworks: ['visa', 'masterCard'],
  merchantCapabilities: ['supports3DS'],
  lineItems: [
    {
      type: 'final',
      label: 'Сумма платежа',
      amount,
    },
    {
      type: 'final',
      label: 'Комиссия',
      amount: 0,
    },
  ],
  total: {
    label: 'Сумма с комиссией',
    amount,
  },
});
