<script>
import Button from '#c/uikit/Button.vue';
import CheckmarkSvg from './Checkmark.vue';
import XmarkSvg from './Xmark.vue';

export default {
  emits: ['close'],

  props: {
    title: String,
    description: String,
    buttonText: {
      type: String,
      default: 'Закрыть',
    },
    type: {
      type: String,
      default: 'positive',
      validator(prop) {
        return ['positive', 'negative'].includes(prop);
      },
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },

  components: {
    Button,
    CheckmarkSvg,
    XmarkSvg,
  },
};
</script>

<template>
  <div class="modal-backdrop">
    <div
      role="dialog"
      class="modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <header class="modal-header">
        <div aria-hidden class="modal-icon">
          <CheckmarkSvg v-if="type === 'positive'" />
          <XmarkSvg v-if="type === 'negative'" />
        </div>

        <h2
          id="modal-title"
          class="modal-title">
          {{ title }}
        </h2>
      </header>

      <section class="modal-body">
        <p
          id="modal-description"
          class="modal-description">
          {{ description }}
        </p>
      </section>

      <footer class="modal-footer">
        <Button @click.native="close">
          {{ buttonText }}
        </Button>
      </footer>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    min-height: 300px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    outline: none;

    & > * ~ * {
      margin-top: 10px;
    }
  }

  .modal-header {}

  .modal-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 100%;
    padding: 20px;

    & svg {
      display: block;
    }
  }

  .modal-title {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
  }

  .modal-body {
    flex: 1;
  }

  .modal-description {
    text-align: center;
  }

  .modal-footer {
    & .btn {
      position: static;
      width: 100%;
    }
  }
</style>
