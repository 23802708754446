import Home from '#v/Home.vue';
import Review from '#v/Review.vue';
import NotFound from '#v/NotFound.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/review',
    name: 'review',
    component: Review,
  },
  {
    path: '/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "result" */ '#v/Result/Index.vue'),
  },
  {
    path: '/add',
    name: 'add',
    component: () => import(/* webpackChunkName: "add" */ '#v/AddView.vue'),
  },
  {
    path: '/activate',
    name: 'activate',
    component: () => import(/* webpackChunkName: "activate" */ '#v/ActivateView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound,
  },
];
