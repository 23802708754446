<template lang="pug">
form(
  @submit.prevent="onSubmit"
  :class="{'method-selecting': fsm && !['init', 'error'].includes(fsm.state)}"
)
  .charges-list
    .item(
      v-for="({ ServiceName, Service, Saldo, id: chargeId, Tariff }) in data.charges"
    )
      .subitem
        .text-info
          .title {{ServiceName}}
          .description {{$t('serviceCode')}}: {{Service}}
        .value
          .value-info
            p(v-if="Saldo >= 0") {{$t('debt')}}: {{formatMoney(Saldo)}} ₽
            p(v-else) {{$t('overpayment')}}: {{formatMoney(Saldo * -1)}} ₽
          Input(
            :tabindex="data.tabindexes[`charge-${chargeId}`]"
            :label="$t('toPayForService')"
            v-model="formData.pay[chargeId]" :validation-state="$v.formData.pay[chargeId]" :validation-messages="validationMessages.pay[chargeId]"
            :flat-error="true"
            postfix="₽"
            mask="#*,##"
            placeholder="0,00"
            :disabled="isProcessing"
            :updateFee="updateFee"
          )
      .subitem.fee(v-if="isComission(chargeId)")
        .text-info: p.comm {{$t('commission')}}:
        .value: .pay-value.comm
          .num {{getFeeRate(chargeId)}}
          .sign %
      .subitem(
        v-for="({\
          Counter,\
          Measure,\
          PrevCounter,\
          id: counterId\
        }) in data.countersByChargeId[chargeId]"
      )
        .text-info.with-icon
          i.icon-node
          .title {{$t('counter')}}
          .description {{$t('serialNumber')}}: {{Counter | serialNumber}}
        .value
          .value-info
            p {{$t('previousCounter')}}: {{PrevCounter}} {{Measure}}
            p {{$t('currentExpense')}}: {{expenses[counterId]}} {{Measure}}
            //-
              p(v-if="expenses[counterId] * Tariff")
                |{{$t('toPay')}}: {{formatMoney(expenses[counterId] * Tariff)}} ₽

          //:postfix="Measure"
          Input(
            :tabindex="data.tabindexes[`counter-${counterId}`]"
            :label="$t('counterValue')"
            :placeholder="$t('enterValue')"
            v-model="formData.counter[counterId]" :validation-state="$v.formData.counter[counterId]" :validation-messages="validationMessages.counter[counterId]"
            :flat-error="true"
            mask="#*"
            inputmode="numeric"
            min="0"
            :disabled="isProcessing"
          )
    .item.total
      .subitem
        .text-info: h2 {{$t('totalToPay')}}:
        .value
          .pay-value
            .num {{this.totalDisplayed}}
            .sign ₽
      .subitem(v-if="getMinSummFee() && getSummFee() > 0")
        .text-info: p.comm {{$t('commission')}}:
        .value: .pay-value.comm
          .num {{getSummFee()}}
          .sign ₽

  SystemMessage.system-message.error(
    v-if="isMaxSummReached"
    type='error'
    :title="maxSummMessage"
  )

  SystemMessage.system-message.error(
    v-if="isTooSmallSumm"
    type='error'
    :title="minSummMessage"
  )

  SystemMessage.system-message.info(
    v-if="hasGlobalComission() && (this.formData.minSummFee > 0)"
    type='error'
    :title="comissionMessage"
  )

  SystemMessage.system-message(
    v-if="systemMessage && systemMessage.type"
    :type="systemMessage.type"
    :title="systemMessage.title"
    :desc="systemMessage.desc")

  .actions.pay-or-cancel
    Button.cancel(@click.native="cancel" :tabindex="data.lastTabIndex + 2") {{$t('cancel')}}
    Button.pay.right(
      type="submit"
      :disabled="!isFormCanBeSent"
      :loading="isProcessing"
      :tabindex="data.lastTabIndex + 1"
    ) {{$t('pay')}}

  .payment-method(
    ref="paymentMethod"
    :class="{ selected: fsm.state === 'emailEntering' || isProcessing }"
  )
    .title
      button.btn-icon(
        @click="back"
        type="button"
        :disabled="isProcessing"
        :tabindex="data.lastTabIndex + 2"
      ): i.icon-arrow_left
      h3(v-if="fsm.state === 'methodSelecting'") {{$t('choosePaymentMethod')}}:
      h3(
        v-if="fsm.state === 'emailEntering' || isProcessing"
      ) {{$t('enterPaymentEmail')}}:

    .email-input-wrapper
      Input.email-input(
        :tabindex="data.lastTabIndex + 3"
        v-model="email"
        :validation-messages="{}"
        :validation-state="{$invalid: emailInvalid}"
        :label="$t('emailLabel')"
        placeholder="example@email.ru"
        :disabled="isProcessing"
        :flat-error="true"
        clearable="true"
        type="email"
        ref="email"
        @keydown="onEmailKey"
      )

    .buttons
      .btn-wrap(
        v-for="({ type, icon, text, disabled }, index) in paymentMethods"
        v-if="!disabled"
        :key="type"
        :class="{ selected: paymentMethod === type }"
      )
        Button(
          :class="type"
          :tabindex="data.lastTabIndex + 4 + index"
          @click.native="processPayment(type)"
          :disabled="isProcessing || disabled === true || emailInvalid"
          :loading="isProcessing && paymentMethod === type"
        )
          i(:class="`icon-${icon}`")
          span(v-if="text") {{$t(text)}}
  div(
    :tabindex="data.lastTabIndex + 100"
    @focus="onFocusEnd"
  )
</template>

<script>
import config from '@/config/client';
import api from '@/libs/api';
import formatMoney from '@/libs/format_money';
import Input from '#form/Input.vue';
import Button from '#c/uikit/Button.vue';
import SystemMessage from '#c/uikit/SystemMessage.vue';

import createStateMachine from './createStateMachine';
import validation from './validation';
import computed from './computed';
import genPaymentResultParams from './genPaymentResultParams';

const google = {};

export default {
  name: 'ChargesList',

  emits: ['cancel'],

  mixins: [validation],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  created() {
    // this.$v.formData.total.$touch();
    this.fsm = createStateMachine(this, { google });
  },

  mounted() {
    document.querySelector('[tabindex="1"]').focus();
    this.formData.total = this.total;
    this.updateFee();
  },

  setup() {
    return {
      createInvoiceTask: api.createTask('ReqCreateInvoice'),
      PGCreateOrderTask: api.createTask('PGCreateOrder'),
      calculateFeeTask: api.createTask('ReqCalculateFee'),
      updateInvoiceTask: api.createTask('ReqUpdateInvoice'),
      PGRegisterQRTask: api.createTask('PGRegisterQR'),
      sendPaymentEmailTask: api.createTask('SendPaymentEmail', {
        retryOnError: () => true,
      }),
      onGooglePaymentAuthorized: null,
    };
  },

  data() {
    return {
      formData: {
        pay: this.data.charges.reduce((acc, { id, ToPay }) => ({
          ...acc,
          [id]: Number(ToPay) || '',
        }), {}),
        fee: this.data.charges.reduce((acc, { id, SizeCommission }) => ({
          ...acc,
          [id]: Number(SizeCommission) || 0,
        }), {}),
        counter: {},
        total: this.total,
        minSummFee: this.data.MinSummFee,
        summFee: this.data.SummFee || 0,
        typePaymentsCode: this.data.charges.reduce((acc, { id, TypeCommission }) => ({
          ...acc,
          [id]: Number(TypeCommission),
        }), {}),
      },
      sumTotal: 0,
      availablePaymentMethods: config.paymentMethods,
      email: '',
      paymentMethod: null,
      fsm: null,
      formatMoney,
    };
  },

  watch: {
    formData: {
      handler() {
        this.fsm.reset();
      },
      deep: true,
    },
  },

  computed,

  methods: {
    onFormData() {
      if (this.isFormCanBeSent) {
        this.fsm.reset();
        this.fsm.selectPaymentMethod();
      }
    },

    onFocusEnd() {
      if (this.fsm.state === 'methodSelecting' || this.fsm.state === 'emailEntering') {
        this.$refs.paymentMethod.querySelector('[tabindex]').focus();
        return;
      }
      document.querySelector('[tabindex="2"]').focus();
    },

    back() {
      switch (this.fsm.state) {
        case 'emailEntering':
          this.fsm.selectPaymentMethod();
          break;
        default:
          this.fsm.reset();
      }
    },

    getFee(chargeId) {
      if (this.formData.payments) {
        return this.formData.payments[chargeId]?.SummFee || 0;
      }
      return 0;
    },

    getFeeRate(chargeId) {
      return this.formData.fee[chargeId] || 0;
    },

    getComissionType(chargeId) {
      return this.formData.typePaymentsCode[chargeId] || 0;
    },

    getSummFee() {
      return this.formData.summFee;
    },

    getMinSummFee() {
      return this.formData.minSummFee;
    },

    isComission(chargeId) {
      if (this.hasGlobalComission() === 0) {
        return false;
      }
      return this.getComissionType(chargeId) || (this.getMinSummFee() && this.getFee(chargeId));
    },

    hasGlobalComission() {
      return this.formData.globalTypeCommission;
    },

    async updateFee() {
      const response = await this.fsm.calculateFee();
      this.formData.isMinFee = response.data.IsMinFee || 0;
      this.formData.summFee = response.data.SummFee || 0;
      this.formData.payments = response.data.Payments?.Payment || [];
      this.formData.sumTotal = response.data.Total || 0;
      this.formData.globalTypeCommission = response.data.globalTypeCommission;
      this.sumTotal = response.data.Total || 0;

      for (let i = 0; i < this.formData.fee.length; i + 1) {
        this.formData.fee[i] = response.data.Payments.Payment[i].SummFee || 0;
      }
    },

    onEmailKey(e) {
      if (e.which !== 13) return;

      e.preventDefault();

      if (this.email && this.fsm.state === 'emailEntering') {
        this.processPayment(this.paymentMethod);
      }
    },

    processPayment(type) {
      console.log('processPayment', type);
      if (!type) return;

      if (this.availablePaymentMethods.indexOf(type) === -1
        && !this.$route.query.enableAllPaymentMethods) return;

      this.paymentMethod = type;

      if (this.fsm.state === 'methodSelecting' && !this.email) {
        this.fsm.enterEmail();
        setTimeout(() => {
          this.$refs.email?.$el.querySelector('input').focus();
        }, 400);
        return;
      }

      if (!this.email) {
        setTimeout(() => {
          this.$refs.email?.$el.querySelector('input').focus();
        }, 400);
        return;
      }

      if (type === 'apple') {
        this.fsm.startApplePay();
        return;
      }

      if (type === 'google') {
        this.fsm.startGooglePay();
        return;
      }
      this.fsm.createInvoice(type);
    },

    async sendPaymentEmail(paymentResultParams) {
      const paymentResultURL = new URL(`${window.location.origin}/result`);
      console.log('sendPaymentEmail');
      Object.entries(paymentResultParams).forEach(([key, val]) => {
        paymentResultURL.searchParams.set(key, val);
      });

      return this.sendPaymentEmailTask.perform({
        paymentResultURL,
        email: this.email.trim(),
        total: this.total,
      });
    },

    async goToPaymentGate({ type, ...rest }) {
      const paymentResultParams = genPaymentResultParams[type](this, rest);
      console.log('goToPaymentGate');
      try {
        await this.sendPaymentEmail(paymentResultParams);
      } catch (e) {
        console.error(e);
      }

      this[`${type}PayRedirect`](paymentResultParams, rest);
    },

    cardPayRedirect(paymentResultParams, { order }) {
      const { data: { Order: { OrderID, SessionID, URL: basePGURL } } } = order;
      const PGURL = new URL(basePGURL);
      PGURL.searchParams.set('ORDERID', OrderID);
      PGURL.searchParams.set('SESSIONID', SessionID);
      window.location = PGURL;
      console.log('cardPayRedirect');
    },

    qrPayRedirect(paymentResultParams) {
      console.log('qrPayRedirect');
      this.$router.push({
        name: 'result',
        query: paymentResultParams,
      });
    },

    applePayRedirect(paymentResultParams) {
      console.log('applePayRedirect');
      this.$router.push({
        name: 'result',
        query: paymentResultParams,
      });
    },

    googlePayRedirect(paymentResultParams) {
      console.log('googlePayRedirect');
      this.$router.push({
        name: 'result',
        query: paymentResultParams,
      });
    },

    cancel() {
      this.$emit('cancel');
    },
  },

  filters: {
    serialNumber(value) {
      const str = String(value);
      if (str.length <= 3) return str;
      return `${str.slice(0, 2)}...${str.slice(-1)}`;
    },
  },

  components: {
    Input,
    Button,
    SystemMessage,
    config,
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'
@import '~./style'
</style>
