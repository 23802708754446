import StateMachine from 'javascript-state-machine';
import genRequests from './genRequests';
import startApplePay from './apple_pay/beginPayment';
import startGooglePay from './google_pay/beginPayment';

export default (self, { google }) => {
  const transition = (name, data) => setTimeout(
    () => self.fsm[name](data), 0,
  );

  const requests = genRequests(self);

  return new StateMachine({
    init: 'init',
    transitions: [
      {
        name: 'selectPaymentMethod',
        from: [
          'init',
          'emailEntering',
          'applePayProcessing',
          'googlePayProcessing',
        ],
        to: 'methodSelecting',
      },
      {
        name: 'enterEmail',
        from: ['methodSelecting', 'applePayProcessing', 'googlePayProcessing'],
        to: 'emailEntering',
      },
      { name: 'startApplePay', from: ['methodSelecting', 'emailEntering'], to: 'applePayProcessing' },
      { name: 'startGooglePay', from: ['methodSelecting', 'emailEntering'], to: 'googlePayProcessing' },
      { name: 'createInvoice', from: ['methodSelecting', 'emailEntering'], to: 'invoiceCreating' },
      { name: 'calculateFee', from: 'init', to: 'init' },
      { name: 'createOrder', from: 'invoiceCreating', to: 'orderCreating' },
      { name: 'updateInvoice', from: 'orderCreating', to: 'invoiceUpdating' },
      { name: 'registerQR', from: 'invoiceUpdating', to: 'QRRegistering' },
      {
        name: 'goToPaymentGate',
        from: [
          'invoiceUpdating',
          'QRRegistering',
          'applePayProcessing',
          'googlePayProcessing',
        ],
        to: 'goingToPaymentGate',
      },
      { name: 'error', from: '*', to: 'error' },
      { name: 'reset', from: '*', to: 'init' },
    ],
    methods: {
      onCreateInvoice: (fsm, type) => requests
        .createInvoice(type)
        .then((invoice) => transition('createOrder', { type, invoice }))
        .catch((err) => transition('error', err)),

      onCalculateFee: () => requests
        .calculateFee(),

      onCreateOrder: (fsm, { type, invoice }) => requests
        .createOrder({ type, invoice })
        .then((order) => transition('updateInvoice', { type, invoice, order }))
        .catch((err) => transition('error', err)),

      onUpdateInvoice: (fsm, { type, invoice, order }) => requests
        .updateInvoice({ invoice, order, type })
        .then(() => {
          const mapping = {
            card: 'goToPaymentGate',
            qr: 'registerQR',
          };
          transition(mapping[type], { type, order, invoice });
        })
        .catch((err) => transition('error', err)),

      // eslint-disable-next-line no-unused-vars
      onRegisterQR: (fsm, { type, order, invoice }) => requests
        .registerQR({ order, type })
        .then((qrData) => transition('goToPaymentGate', {
          type, order, invoice, qrData,
        }))
        .catch((err) => transition('error', err)),

      onStartApplePay: () => startApplePay({
        amount: self.sumTotal,
        feeSize: self.formData.summFee,
        email: self.email,
        createInvoice: requests.createInvoice,
        AbonentID: self.data.AbonentID,
      }).then(({ order, invoice }) => transition('goToPaymentGate', {
        type: 'apple', order, invoice,
      })).catch((err) => {
        if (err?.message === 'canceled') {
          transition('enterEmail');
          return;
        }
        transition('error', err);
      }),

      onStartGooglePay: () => startGooglePay({
        paymentsClient: google.paymentsClient,
        amount: self.sumTotal,
        feeSize: self.formData.summFee,
        email: self.email,
        createInvoice: requests.createInvoice,
        AbonentID: self.data.AbonentID,
        self,
      }).then(({ order, invoice }) => transition('goToPaymentGate', {
        type: 'google', order, invoice,
      })).catch((err) => {
        if (err?.message === 'canceled' || err?.statusCode === 'CANCELED') {
          transition('enterEmail');
          return;
        }
        transition('error', err);
      }),

      onGoToPaymentGate: (fsm, data) => self.goToPaymentGate(data),

      onError: (fsm, err) => console.log(err),
    },
  });
};
