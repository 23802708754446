import api from '@/libs/api';
import genSessionData from './genSessionData';
import genOrderData from './genOrderData';

export default ({
  amount,
  feeSize,
  email,
  createInvoice,
  // updateInvoice,
  AbonentID,
}) => new Promise((resolve, reject) => {
  const applePaySessionTask = api.createTask('GetApplePaySession');
  const makeApplePayTask = api.createTask('MakeApplePay');

  const session = new window.ApplePaySession(1, genSessionData(amount));

  session.oncancel = () => {
    reject(new Error('canceled'));
  };

  session.onvalidatemerchant = async (event) => {
    const { data } = await applePaySessionTask.perform({ url: event.validationURL });
    const tokenBody = JSON.parse(data.token.tokenBody);
    session.completeMerchantValidation(tokenBody);
  };

  session.onpaymentauthorized = async (event) => {
    try {
      const { data: invoice } = await createInvoice();

      const paymentToken = JSON.stringify(event.payment.token);
      const shippingContact = JSON.stringify(event.payment.shippingContact);

      const orderData = genOrderData({
        amount, feeSize, paymentToken, shippingContact, email, AbonentID, reference: invoice.UID,
      });

      const { data: { operation: order } } = await makeApplePayTask.perform(orderData);

      // await updateInvoice({ invoice: { data: invoice }, order, type: 'apple' });

      session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
      resolve({ order, invoice });
    } catch (e) {
      session.completePayment(window.ApplePaySession.STATUS_FAILURE);
      reject(e);
    }
  };

  session.begin();
});
