import numeral from 'numeral';

export default ({
  amount, feeSize, paymentToken, shippingContact, email, AbonentID, reference,
}) => ({
  amount: numeral(amount).multiply(100).value(),
  paymentToken,
  shippingContact,
  email,
  fee: numeral(feeSize).multiply(100).value(),
  desc: `Оплата услуг по ЛС №${AbonentID}`,
  language: 'RUS',
  reference,
});
