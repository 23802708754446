const thousandsDivider = ' ';
const fractionDivider = ',';

// Example: 68300.423432 -> 68,300.42
export default (val, decimalPlaces = 2) => {
  if (Number.isNaN(parseFloat(val))) return null;

  // Split 2354.434343 into ['2354', '434343']
  const parts = String(val).split('.');

  // '2354' -> '2,354'
  const wholePart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsDivider);

  // If no need fraction part
  if (!decimalPlaces) return wholePart;

  // Get number part after dot and slice it to the required decimal places
  let fractionPart = (parts[1] || '').slice(0, decimalPlaces);

  // Add zeros to fill required decimal places
  fractionPart += new Array((decimalPlaces + 1) - fractionPart.length).join('0');

  if (fractionPart) {
    fractionPart = `${fractionDivider}${fractionPart}`;
  }

  return `${wholePart}${fractionPart}`;
};
