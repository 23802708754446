import config from '@/config/client';

export const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

export const tokenizationSpecification = {
  type: 'PAYMENT_GATEWAY',
  parameters: {
    gateway: config.googlePay.gateway,
    gatewayMerchantId: config.googlePay.gatewayMerchantId,
  },
};

export const allowedCardNetworks = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'];

export const allowedAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

export const baseCardPaymentMethod = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods,
    allowedCardNetworks,
  },
};

export const cardPaymentMethod = {
  tokenizationSpecification,
  ...baseCardPaymentMethod,
};

export const genPaymentDataRequest = ({ totalPrice }) => ({
  ...baseRequest,
  callbackIntents: ['PAYMENT_AUTHORIZATION'],
  allowedPaymentMethods: [cardPaymentMethod],
  transactionInfo: {
    displayItems: [
      {
        label: 'Subtotal',
        type: 'SUBTOTAL',
        price: totalPrice,
      },
      {
        label: 'Tax',
        type: 'TAX',
        price: '0.00',
      },
    ],
    totalPriceLabel: 'TOTAL',
    totalPriceStatus: 'FINAL',
    totalPrice,
    currencyCode: 'RUB',
    countryCode: 'RU',
  },
  merchantInfo: {
    merchantName: config.googlePay.merchantName,
    merchantId: config.googlePay.merchantId,
  },
});
