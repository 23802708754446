<template lang="pug">
.input-container(:class="containerClasses")
  label
    .label-text(v-if="label") {{label}}
    .input-wrap
      slot
    .postfix(v-if="postfix") {{postfix}}
  .errors(v-if="errors[0]"): .error(v-for="msg in errors" :key="msg.id") {{msg.text}}
</template>

<script>
import { genErrors } from '@/libs/validation_rules';

export default {
  name: 'InputContainer',

  emits: ['focus', 'blur'],

  mounted() {
    this.bindFocusBlurStateUpdate();
  },

  props: {
    label: String,
    validationMessages: Object,
    validationState: Object,
    disabled: Boolean,
    postfix: String,
    flatError: Boolean,
    clearable: [Boolean, String],
  },

  data() {
    return {
      state: 'blur',
    };
  },

  computed: {
    errors() {
      return genErrors(
        this.validationMessages, this.validationState,
      ).slice(0, 1);
    },

    containerClasses() {
      return {
        'input-container-invalid': this.validationState?.$invalid,
        focus: this.state === 'focus',
        disabled: this.disabled,
        'with-postfix': this.postfix,
        'flat-error': this.flatError,
        clearable: this.clearable,
      };
    },
  },

  methods: {
    bindFocusBlurStateUpdate() {
      const [input] = this.$el.querySelectorAll('input, select, textarea');
      input?.addEventListener('focus', () => {
        this.$emit('focus');
        this.state = 'focus';
      });
      input?.addEventListener('blur', () => {
        this.$emit('blur');
        this.state = 'blur';
      });
    },
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

$height = 56px

.input-container
  border: 1px solid $color-border
  border-radius: 4px
  min-height: $height
  padding: 8px 16px
  padding-top: 9px
  margin-bottom: $input-gap
  position: relative
  trans('border-color, background-color, opacity')
  transition-duration: 0.1s
  &:last-child
    margin-bottom: 0

  &.focus
    border-color: $color-base

  &.disabled
    background-color: $color-border
    pointer-events: none
    opacity: .7

  &.with-postfix
    padding-right: 45px

  &:after
    content: "\e901"
    font-family: icomoon
    color: $color-error
    position: absolute
    line-height: 1
    font-size: 25px
    height: 1em
    right: 16px
    top: 0
    bottom: 0
    margin: auto
    opacity: 0
    visibility: hidden
    trans('opacity, visibility')
    transition-duration: 0.1s

label, .label
  display: block
  height: 100%
  color: $color-label
  font-size: 12px

.input-wrap
  margin-top: 4px

.input-container-invalid
  border-color: $color-error !important
  .errors, &:after
    opacity: 1
    visibility: visible

  &.clearable
    &:after
      display: none

  &.flat-error
    .errors, &:after
      opacity: 0
      visibility: hidden
    &:hover
      .errors
        opacity: 1
        visibility: visible

.errors
  white-space: normal
  position: absolute
  opacity: 0
  visibility: hidden
  trans('opacity, visibility')
  transition-duration: 0.1s
  top: calc(100% + 1px)
  left: 4px
  right: 4px
  margin: auto
  background-color: lighten($color-error, 87%)
  font-size: 14px
  color: $color-error
  border-radius: 4px
  border-top-left-radius: 0
  border-top-right-radius: 0
  padding: 9px 12px 7px 12px
  min-height: 33px
  z-index: 5
  @media(max-width: 767px)
    font-size: 12px

.postfix
  position: absolute
  font-size: 16px
  height: @font-size
  right: 16px
  abs-center('vt')
</style>
