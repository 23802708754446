import CreateValidationMixin from '@/mixins/validation';

const createCounterRules = (self) => self.data.counters
  .reduce((acc, {
    id,
    PrevCounter,
  }) => ({
    ...acc,
    [id]: {
      // required: {
      //   rule: required,
      //   message: () => self.$t('counterRequired'),
      // },
      minPrev: {
        rule: (v) => v === undefined || v === '' || Number(v) >= Number(PrevCounter),
        message: () => self.$t('counterMin'),
      },
      maxPrev: {
        rule: (v) => v === undefined || v === '' || Number(v) - Number(PrevCounter) <= 3000,
        message: () => self.$t('maxCounterDifference', { val: 3000 }),
      },
    },
  }), {});

const createPayRules = (self) => self.data.charges
  .reduce((acc, { id }) => ({
    ...acc,
    [id]: {
      // required: {
      //   rule: required,
      //   message: () => self.$t('payAmountRequired'),
      // },
    },
  }), {});

export default CreateValidationMixin((self) => ({
  formData: {
    counter: createCounterRules(self),
    pay: createPayRules(self),
    // email: {
    // required: {
    //     rule: required,
    //     message: () => self.$t('emailRequired'),
    //   },
    // email: {
    //   rule: email,
    //   message: () => self.$t('emailInvalid'),
    // },
    // },
  },
}));
