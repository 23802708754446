<template lang="pug">
a.btn(
  v-if="href"
  :class="{ loading }"
  :disabled="loading || disabled"
  :href="href"
  :target="target"
)
  span.text: slot
  Spinner
button.btn(v-else :class="{ loading }" :disabled="loading || disabled" :type="type")
  span.text: slot
  Spinner
</template>

<script>
import Spinner from './Spinner.vue';

export default {
  name: 'Button',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    href: {
      type: [String, URL],
    },
    target: {
      type: String,
    },
  },

  components: {
    Spinner,
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

.btn
  position: relative
  display: inline-block
  cursor: pointer
  border: none
  background-color: $color-base
  border-radius: 8px
  font-weight: 400
  color: $color-white
  padding: 10px 70px
  // outline: none
  user-select: none
  min-height: 48px
  line-height: 1.8

  &[disabled]
    cursor: not-allowed
    opacity: .5
    &:hover
      background-color: @background-color

  trans('background-color')
  &:hover
    background-color: darken(@background-color, 30%)

.text
  trans('opacity, vibisility')

.spinner
  font-size: 22px
  opacity: 0
  visibility: hidden
  position: absolute
  trans('opacity, vibisility')
  abs-center()

.loading
  .text
    opacity: 0
    visibility: hidden
  .spinner
    opacity: 1
    visibility: visible

</style>
