import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import { VuelidatePlugin as vuelidate } from '@vuelidate/core';
import VueCompositionAPI from '@vue/composition-api';
import ru from '@/i18n/ru.yml';
import App from './App.vue';
import createRouter from './router';
import createStore from './store';
import '#a/style/index.styl';

Vue.config.productionTip = true;
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'ru', // set locale
  messages: { ru }, // set locale messages
});
Vue.use(vuelidate);
Vue.use(VueCompositionAPI);

new Vue({
  router: createRouter(),
  store: createStore(),
  i18n,
  render: (h) => h(App),
}).$mount('#app');
