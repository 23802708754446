/* eslint-disable no-use-before-define */
// @TODO Recreate and reimagine validation rules create: $each support, nested props support
const getFirstProp = (obj) => Object.keys(obj)[0];

const getRulesEntries = (rules) => {
  const root = getFirstProp(rules);
  return Object.entries(rules[root].$each || rules[root]);
};

const createFieldRules = (fieldRules, genValue) => Object.entries(fieldRules)
  .reduce((acc2, [name, settings]) => {
    const rule = name === '$each' ? createMap(fieldRules[name], genValue) : genValue(settings);
    return {
      ...acc2,
      [name]: rule || createFieldRules(fieldRules[name], genValue),
    };
  }, {});

const createMap = (rules, genValue) => {
  if (!rules) return {};

  return getRulesEntries(rules).reduce((acc, [field, fieldRules]) => {
    const fieldMap = createFieldRules(fieldRules, genValue);

    return {
      ...acc,
      [field]: fieldMap,
    };
  }, {});
};

export const createMessages = (rules) => createMap(rules, ({ message }) => message);

export const createRules = (rules) => ({
  [getFirstProp(rules)]: createMap(rules, ({ rule }) => rule),
});

export const genErrors = (messages, state) => Object
  .entries(messages || [])
  .filter(([rule]) => state?.[rule]?.$invalid)
  .reduce((acc, [, message]) => [...acc, {
    text: typeof message === 'function' ? message() : message,
  }], []);
