<template lang="pug">
.spinner(v-once :class="color")
  - for(let i = 1; i <= 12; i++)
    div
</template>

<script>
export default {
  name: 'Button',

  props: {
    color: {
      type: String,
      required: false,
      default: 'white',
    },
  },

  data() {
    return {
      clr: this.color,
    };
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

.spinner
  color: $color-white
  display: inline-block
  position: relative
  width: 1em
  height: 1em
  font-size: 40px

  &.white
    div:after
      background: $color-white

  &.base
    div:after
      background: $color-base
  &.note
    div:after
      background: $color-note

  div
    transform-origin: 0.5em 0.5em
    animation: spinner 1.2s linear infinite

    &:after
      content: " "
      display: block
      position: absolute
      top: 0.0375em
      left: 0.4625em
      width: 0.075em
      height: 0.225em
      border-radius: 4px

    &:nth-child(1)
      transform: rotate(0deg)
      animation-delay: -1.1s

    &:nth-child(2)
      transform: rotate(30deg)
      animation-delay: -1s

    &:nth-child(3)
      transform: rotate(60deg)
      animation-delay: -0.9s

    &:nth-child(4)
      transform: rotate(90deg)
      animation-delay: -0.8s

    &:nth-child(5)
      transform: rotate(120deg)
      animation-delay: -0.7s

    &:nth-child(6)
      transform: rotate(150deg)
      animation-delay: -0.6s

    &:nth-child(7)
      transform: rotate(180deg)
      animation-delay: -0.5s

    &:nth-child(8)
      transform: rotate(210deg)
      animation-delay: -0.4s

    &:nth-child(9)
      transform: rotate(240deg)
      animation-delay: -0.3s

    &:nth-child(10)
      transform: rotate(270deg)
      animation-delay: -0.2s

    &:nth-child(11)
      transform: rotate(300deg)
      animation-delay: -0.1s

    &:nth-child(12)
      transform: rotate(330deg)
      animation-delay: 0s

@keyframes spinner
  0%
    opacity: 1
  100%
    opacity: 0
</style>
