import getMerchantId from '../../utils/getMerchantId';
import genInvoiceData from './genInvoiceData';
import genPGOrderData from './genPGOrderData';

export default (self) => ({
  createInvoice(paymentType) {
    console.log('createInvoice');
    console.log(self.data);
    console.log(self.formData);

    const { email } = self;
    const invoiceData = {
      ...genInvoiceData({
        data: self.data,
        email,
        paymentType,
        total: self.total,
        formData: self.formData,
      }),
      PaymentType: paymentType,
    };
    return self.createInvoiceTask.perform(invoiceData);
  },

  calculateFee() {
    const { email } = self;
    return self.calculateFeeTask.perform(genInvoiceData({
      data: self.data,
      email,
      total: self.total,
      formData: self.formData,
    }));
  },

  createOrder({ type, invoice }) {
    const { email } = self;
    const total = (Number(invoice.data.OrderSumm)).toFixed(2);
    const smfee = (Number(invoice.data.SummFee)).toFixed(2);

    return self.PGCreateOrderTask.perform(genPGOrderData({
      invoice,
      data: self.data,
      total,
      smfee,
      email,
      type,
    }));
  },

  updateInvoice({ invoice, order, type }) {
    return self.updateInvoiceTask.perform({
      PaymentType: type,
      UID: invoice.data.UID,
      InvoiceStateCode: 1,
      Additional: {
        Merchant: type === 'apple' ? 'best2pay' : getMerchantId(type),
        OrderID: type === 'apple' ? order.id : order.data.Order.OrderID,
        SessionID: type === 'apple' ? null : order.data.Order.SessionID,
      },
    });
  },

  registerQR({ order, type }) {
    return self.PGRegisterQRTask.perform({
      Merchant: getMerchantId(type),
      OrderID: order.data.Order.OrderID,
      SessionID: order.data.Order.SessionID,
      org: self.data.TypePaymentCode,
    });
  },
});
