import numeral from 'numeral';
import { email } from '@vuelidate/validators';
import formatMoney from '@/libs/format_money';
import { genErrors } from '@/libs/validation_rules';
import config from '@/config/client';

export default {

  sbpEnabled() {
    return this.$store.getters.selected_org?.sbp;
  },
  paymentMethods() {
    return [
      {
        type: 'card',
        icon: 'card',
        text: 'payWithCard',
        disabled: config.paymentMethods.indexOf('card') === -1 && !this.$route.query.enableAllPaymentMethods,
      },
      {
        type: 'qr',
        icon: 'qr',
        text: 'payWithFPS',
        disabled: (config.paymentMethods.indexOf('qr') === -1 && !this.$route.query.enableAllPaymentMethods) || (!this.sbpEnabled),
      },
    ];
  },
  emailInvalid() {
    if (!this.email) return false;
    return !email.$validator(this.email);
  },

  expenses() {
    return this.data.counters.reduce((acc, { id, PrevCounter }) => {
      const expense = this.formData.counter[id] - PrevCounter || 0;
      return {
        ...acc,
        [id]: expense < 0 ? 0 : expense,
      };
    }, {});
  },

  isFormCanBeSent() {
    return !this.isTooSmallSumm && !this.isMaxSummReached;
  },

  isTooSmallSumm() {
    return this.sumTotal < config.payment.min;
  },

  isMaxSummReached() {
    return this.sumTotal > config.payment.max;
  },

  isComissionMessageShown() {
    return this.hasGlobalComission() && (+this.formData.minSummFee > 0);
  },

  maxSummMessage() {
    return this.$t('maxPaymentAmount', { val: formatMoney(config.payment.max) });
  },

  minSummMessage() {
    return this.$t('minPaymentAmount', { val: formatMoney(config.payment.min) });
  },

  comissionMessage() {
    return `${this.$t('minCommision')} ${this.formData.minSummFee} ₽`;
  },

  total() {
    return Object.values(this.formData.pay)
      .map((val) => Number(String(val).replace(',', '.')) || 0)
      .reduce((a, b) => numeral(a).add(b).value(), 0) || 0;
  },

  totalDisplayed() {
    return this.sumTotal ? formatMoney(this.sumTotal) : 0;
  },

  isShowMessage() {
    return Object.values(this.formData.typePaymentsCode).find((typeValue) => typeValue !== 0) || 0;
  },

  totalFormatted() {
    return formatMoney(this.total);
  },

  isProcessing() {
    return !['init', 'methodSelecting', 'emailEntering', 'error'].includes(this.fsm.state);
  },

  systemMessage() {
    const error = genErrors(
      this.validationMessages?.total,
      this.$v.formData.sumTotal,
    )?.[0]?.text;

    if (error) {
      return {
        type: 'error',
        // title: this.$t('incorrectTotal'),
        desc: error,
      };
    }

    if (this.fsm.state === 'error') {
      return {
        type: 'error',
        title: this.$t('errorDuringPaymentPrepare'),
        desc: this.$t('tryAgainLater'),
      };
    }

    return null;
  },
};
