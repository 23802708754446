import Vuex from 'vuex';
import api from '@/libs/api';

export default () => new Vuex.Store({
  state: {
    orgs_loaded: false,
    regions_loaded: false,
    selected_organization_id: null,
    selected_department_id: null,
    accountDetails: null,
    organizations: [].map(({
      id: value, name: label, regionId, sbp,
    }) => ({
      value, label, regionId, id: `${value}|${regionId}`, sbp,
    }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    regions: {},
    organizationsByRegion: {},
  },
  getters: {
    loaded_orgs: (state) => state.orgs_loaded && state.regions_loaded,
    organizations: (state) => state.organizations,
    selected_org: (state) => (state.selected_organization_id
      ? state.organizations.find((org) => org.id === state.selected_organization_id)
      : null),
  },
  mutations: {
    set_selected_organization_id(state, id) {
      state.selected_organization_id = id;
    },
    set_selected_organization_id_by_code(state, code) {
      console.log('set_selected_organization_id_by_code', code, typeof code);
      try {
        const org = state.organizations?.find((_org) => _org.code === code);
        console.log('found', org);
        state.selected_organization_id = org?.id;
      } catch (e) {
        console.log('cant set org', e);
      }
    },
    set_selected_department_id(state, id) {
      state.selected_department_id = id;
    },
    set_organizations(state, organizations) {
      state.organizations = organizations;
      state.orgs_loaded = true;
    },
    set_organizationsByRegion(state, organizations) {
      state.organizationsByRegion = organizations;
    },
    set_regions(state, regions) {
      state.regions = regions;
      state.regions_loaded = true;
    },
    accountDetails(state, payload) {
      if (!payload) {
        state.accountDetails = payload;
        return;
      }

      const tabindexes = payload.charges
        .reduce((acc, { id }) => {
          const chargeTabindex = acc.length + 1;

          const counters = payload.counters
            .filter(({ chargeId }) => chargeId === id)
            .reduce((acc2, counter, index) => [
              ...acc2,
              { [`counter-${counter.id}`]: chargeTabindex + index + 1 },
            ], []);

          return [
            ...acc,
            { [`charge-${id}`]: chargeTabindex },
            ...counters,
          ];
        }, [])
        .reduce((acc, item) => {
          const [key, val] = Object.entries(item)[0];
          return { ...acc, [key]: val + 1 };
        }, {});

      state.accountDetails = {
        ...payload,
        tabindexes,
        lastTabIndex: Object.keys(tabindexes).length,
        countersByChargeId: payload.counters.reduce((acc, counter) => ({
          ...acc,
          [counter.chargeId]: [...(acc[counter.chargeId] || []), counter],
        }), {}),
      };
    },
    set_grouped_organizations_region(state) {
      const grouped = state.organizations?.reduce((acc, { id: value, label, region_id }) => {
        const regionName = state.regions?.filter((region) => region.id === region_id)[0]?.name;
        const currentItems = acc[regionName] || [];
        return {
          ...acc,
          [regionName]: [...currentItems, { value, label, id: value }]
            .sort((a, b) => a.label.localeCompare(b.label)),
        };
      },
      {});
      state.organizationsByRegion = grouped;
      return grouped;
    },
  },
  actions: {
    async fetch_organizations({ commit }) {
      try {
        const orgs = await api.createTask('APIOrganizations').perform();
        commit('set_organizations', orgs.data.organizations);
      } catch (e) {
        console.error(e.trace);
      }
    },
    async fetch_regions({ commit }) {
      try {
        const regions = await api.createTask('APIRegions').perform();
        commit('set_regions', regions.data.regions);
      } catch (e) {
        console.error(e.trace);
      }
    },
  },
  modules: {
  },
});
