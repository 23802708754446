/* eslint-disable no-console */
import { useTask } from 'vue-concurrency';
// import { GraphQLClient } from 'graphql-request';
import axios from 'axios';
// import '@/libs/axios_mocks';
import config from '@/config/client';
import requestMappings from './mappings';

const settings = {
  // graphQLURL: 'https://оплата.смородина.онлайн/graphql',
  proxyURL: (endpoint) => `${config.API}${endpoint}`,
};

const repeatedCall = (
  fn, {
    retry = () => false, retryOnError = () => true, maxRetries = 5, delay = 5000,
  },
) => {
  const iter = async (retryNumber = 1) => {
    try {
      const response = await fn();
      if (!retry(response) || retryNumber === maxRetries) {
        return response;
      }
    } catch (e) {
      if (retryNumber === maxRetries || !retryOnError(e)) {
        throw e;
      }
    }

    await new Promise((resolve) => setTimeout(resolve, delay));
    return iter(retryNumber + 1);
  };
  return iter();
};

class API {
  constructor({ graphQLClient, mappings, ajax }) {
    this.graphQLClient = graphQLClient;
    this.ajax = ajax;
    this.mappings = mappings;
  }

  call(entity, data, options = {}) {
    if (options.retry || options.retryOnError) {
      return repeatedCall(
        () => this.call(entity, data),
        options,
      );
    }

    const mapping = this.mappings[entity];
    if (!mapping) throw new Error(`Incorrect entity: ${entity}`);
    return this[mapping.type](mapping, data, options);
  }

  createTask(entity, options = {}) {
    const self = this;
    return useTask(function* load(signal, data) {
      return yield self.call(entity, data, options);
    }).drop().maxConcurrency(1);
  }

  // eslint-disable-next-line class-methods-use-this
  async performTask(task, data = null) {
    try {
      return await task.perform(data);
    } catch (e) {
      return null;
    }
  }

  graphql({ query, variables }, data) {
    return this.graphQLClient.request(query, variables || data);
  }

  proxy({ endpoint, method, preBody = (data) => data }, data = null) {
    return this.ajax[method](settings.proxyURL(endpoint), preBody(data))
      .catch((e) => {
        const resData = e.response?.data;
        if (resData) {
          // eslint-disable-next-line no-throw-literal
          throw { ...resData, status: e?.response?.status };
        }
        throw e;
      });
  }
}

if (process.env.NODE_ENV !== 'test') {
  axios.interceptors.request.use((request) => {
    console.log('Starting Request', request.url, request.data);
    return request;
  });

  axios.interceptors.response.use((response) => {
    console.log('Response:', response.data);
    return response;
  });
}

export default new API({
  // graphQLClient: new GraphQLClient(settings.graphQLURL),
  ajax: axios,
  mappings: requestMappings,
});
