/* eslint-disable no-param-reassign */
import numeral from 'numeral';
import api from '@/libs/api';
import { genPaymentDataRequest } from './settings';
import genPaymentResultParams from '../genPaymentResultParams';

export default async ({
  paymentsClient,
  amount,
  feeSize,
  email,
  createInvoice,
  AbonentID,
  self,
}) => new Promise((resolve, reject) => {
  const registerOrderTask = api.createTask('BPRegister');
  const googlePayDirectTask = api.createTask('GooglePayDirect');

  const redirectTo3ds = (data) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    let form = (doc.querySelectorAll('form'));
    if (!form || !form[0]) return;
    [form] = form;
    form.setAttribute('hidden', 'hidden');
    document.body.appendChild(form);

    setTimeout(() => {
      form.submit();
    }, 200);
  };

  self.onGooglePaymentAuthorized = async (paymentData) => {
    try {
      const { token: cryptogram } = paymentData.paymentMethodData.tokenizationData;

      const { data: invoice } = await createInvoice();

      let resultPageParams = genPaymentResultParams.google(self, { invoice, order: { id: 'null' } });
      resultPageParams.isB2PRedirect = true;
      resultPageParams = new URLSearchParams(resultPageParams).toString();

      const { data: { order } } = await registerOrderTask.perform({
        amount: numeral(amount).multiply(100).value(),
        fee: numeral(feeSize).multiply(100).value(),
        reference: invoice.UID,
        description: `Оплата услуг по ЛС №${AbonentID}`,
        url: `${window.location.origin}/result?${resultPageParams}`,
        email,
      });

      const { data } = await googlePayDirectTask.perform({
        id: order.id,
        cryptogram,
        email,
      });

      if (typeof data === 'string' && data.indexOf('<html>') !== -1) {
        redirectTo3ds(data);
      } else {
        resolve({ order, invoice });
      }

      return { transactionState: 'SUCCESS' };
    } catch (e) {
      reject(e);
      return { transactionState: 'ERROR' };
    }
  };

  const paymentDataRequest = genPaymentDataRequest({
    totalPrice: String(amount),
  });

  paymentsClient.loadPaymentData(paymentDataRequest).catch((err) => {
    console.error(err);
    reject(err);
  });
});
