import numeral from 'numeral';
import config from '@/config/client';
import getMerchantId from '../../utils/getMerchantId';

export default ({
  invoice, data, total, smfee, email, type,
}) => {
  const PGResponseURL = new URL(`${config.API}/PGResponse`);
  PGResponseURL.searchParams.set('InvoiceUID', invoice.data.UID);
  PGResponseURL.searchParams.set('org', data.TypePaymentCode);
  PGResponseURL.searchParams.set('acc', data.AbonentID);
  PGResponseURL.searchParams.set('month', data.Month);
  PGResponseURL.searchParams.set('email', email);

  const Order = {
    OrderType: 'Purchase',
    Merchant: getMerchantId(type),
    Amount: numeral(total).multiply(100).value(),
    Currency: '643',
    Description: `Оплата услуг по ЛС №${data.AbonentID}${type === 'qr' ? ` // ID: ${invoice.data.UID}` : ''}`,
    ApproveURL: PGResponseURL,
    CancelURL: PGResponseURL,
    DeclineURL: PGResponseURL,
    Fee: numeral(smfee).multiply(100).value(),
  };

  if (type === 'qr') {
    Order.AddParams = {
      OrderExpirationPeriod: 4320,
    };
  }

  return {
    Order,
    type,
    org: data.TypePaymentCode,
  };
};
