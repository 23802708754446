<template lang="pug">
InputContainer(
  :validationMessages="validationMessages"
  :validationState="validationState"
  :label="label"
  :disabled="disabled"
  :postfix="postfix"
  :flat-error="flatError"
  :class="{ 'has-value': modelValue && clearable, numeric: inputmode === 'numeric' }"
  :clearable="clearable"
).input-container
  input(
    :tabindex="tabindex"
    :value="modelValue"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="readonly"
    :type="type"
    :inputmode="inputmode"
    :min="min"
    @input="onInput($event.target.value)"
    @blur="onBlur"
    @keyup="$emit('keyup', $event)"
    @change="onChange"
    @keydown="onKeyDown"
    v-maska="mask"
  )

  .arrows(v-if="inputmode === 'numeric'")
    button(type="button" @click="increment"): i.icon-arrow_up
    button(type="button" @click="decrement"): i.icon-arrow_down

  button.btn-clean.btn-reset(
    type="button" v-if="modelValue && clearable" @click="reset"
  ): i.icon-close
</template>

<script>
import { maska } from 'maska';
import InputContainer from './InputContainer.vue';

export default {
  name: 'Input',

  emits: ['update:modelValue', 'keyup', 'keydown'],

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    label: String,
    placeholder: String,
    validationMessages: Object,
    validationState: Object,
    disabled: Boolean,
    readonly: Boolean,
    flatError: Boolean,
    postfix: String,
    inputmode: String,
    mask: [String, Object],
    min: [String, Number],
    tabindex: [String, Number],
    clearable: [Boolean, String],
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: [String, Number, Date],
      default: null,
    },
    updateFee: Function,
  },

  methods: {
    onBlur() {
      this.validationState?.$touch?.();
    },

    onInput(value) {
      // this.validationState?.$touch();
      this.$emit('update:modelValue', value);
      if (document.querySelector('.pay.right')) document.querySelector('.pay.right').disabled = true;
    },

    onChange() {
      if (this.label === 'К оплате по услуге') {
        this.updateFee();
      }
      if (document.querySelector('.pay.right')) document.querySelector('.pay.right').disabled = false;
    },

    reset() {
      this.$emit('update:modelValue', '');
    },

    onKeyDown(e) {
      this.$emit('keydown', e);

      if (this.inputmode === 'numeric') {
        if (e.which === 40) {
          e.preventDefault();
          this.decrement();
        }
        if (e.which === 38) {
          e.preventDefault();
          this.increment();
        }
      }
    },

    increment() {
      if (this.inputmode !== 'numeric') return;
      const current = Number(this.modelValue) || 0;
      this.$emit('update:modelValue', current + 1);
    },

    decrement() {
      if (this.inputmode !== 'numeric') return;
      const current = Number(this.modelValue) || 0;
      let newValue = current - 1;
      if (this.min !== undefined && newValue < this.min) {
        newValue = Number(this.min);
      }
      this.$emit('update:modelValue', newValue);
    },
  },

  directives: { maska },

  components: {
    InputContainer,
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

input
  border: none
  display: block
  width: 100%
  outline: none
  padding: 0
  background-color: transparent
  font-size: $font-size
  color: $color-text

  &::-ms-clear
    display: none
  &:-ms-input-placeholder
    color: $color-text
    opacity: 0.3

.input-container
  &.has-value
    padding-right: 50px
  &.numeric
    input
      padding-right: 50px

.btn-reset
  position: absolute
  right: 0
  font-size: 16px
  top: 0
  bottom: 0
  margin: auto
  padding: 16px
  height: 48px
  z-index: 5
  opacity: .5
  trans('opacity')
  &:hover
    opacity: 1

.arrows
  position: absolute
  right: 0
  top: 0
  bottom: 0
  width: 50px
  button
    display: block
    width: 100%
    height: 50%
    border: none
    outline: none
    background-color: $color-border
    padding: 0
    margin: 0
    color: $color-text
    padding-top: 0.4em
    opacity: .4
    &:hover
      opacity: .7
    &:active
      opacity: 1
</style>
