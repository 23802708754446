<template lang="pug">
.message(:class="{ [type]: true, 'desc-only': !title }")
  i(:class="iconClass" v-if="type !== 'loading'")
  Spinner(v-if="type === 'loading'" color="note")
  .text
    .title {{ title }}
    .desc {{ desc }}

</template>

<script>
import Spinner from './Spinner.vue';

export default {
  name: 'SystemMessage',

  props: {
    title: String,
    desc: String,
    type: {
      type: String,
      default: 'error',
    },
  },

  computed: {
    iconClass() {
      const mapping = {
        error: 'icon-alert',
        success: 'icon-check',
      };
      return mapping[this.type] || 'icon-info';
    },
  },

  components: {
    Spinner,
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

$icon-gap = 16px
$icon-size = 32px

.message
  color: $color-note
  background-color: rgba(@color, .15)
  border-radius: 4px
  position: relative
  padding-left: ($icon-gap * 2 + $icon-size)
  padding-right: 20px
  padding-top: 8px
  padding-bottom: @padding-top

  &.success
    color: $color-success
    background-color: rgba(@color, .15)

  &.error
    color: $color-error
    background-color: rgba(@color, .15)
  &.info
    color: #fff
    background-color: #0078D2

i, .spinner
  font-size: $icon-size
  height: 1em
  width: 1em
  abs-center('vt')
  left: $icon-gap

.desc
  font-size: 12px

.desc-only
  padding-top: 16px
  padding-bottom: @padding-top
  .desc
    font-size: 15px
    padding-top: 0.3em
    @media(max-width: 767px)
      font-size: 14px

</style>
