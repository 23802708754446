<template lang="pug">
#services-review
  h1 {{$t('servicesToPay')}}

  .preloader(v-if="!fully_loaded")
    Spinner(color="base")
    span {{$t('dataLoading')}}
  #account-selector(v-if="status === 'error'")
    AccountSelectorForm(
      ref="accountSelectorForm"
      :organizations="organizations"
      :regions="regions"
      :storage="storage"
    )
  template(v-if="fully_loaded")
    p.org {{$t('provider')}}: {{orgName}}
    p.acc(v-if="data") {{$t('account')}}: {{$route.query.acc}}
    p.name(v-if="data") {{$t('address')}}: {{address}}
    ChargesList.charges-container(
      :data="data"
      @cancel="cancel"
    )
</template>

<script>
import api from '@/libs/api';
import * as storage from '@/libs/storage';
import AccountSelectorForm from '#c/account_selector/Form.vue';
import Spinner from '#c/uikit/Spinner.vue';
import ChargesList from '#c/charges_list/ChargesList.vue';

export default {
  name: 'Review',

  created() {
    this.loadData();
  },

  setup() {
    return {
      loadAccountTask: api.createTask('ReqCheckAbonent'),
      storage,
    };
  },

  watch: {
    $route() {
      this.loadData();
    },
  },

  methods: {
    async loadData() {
      const { org: TypePaymentCode, acc: AbonentID } = this.$route.query;
      if (!TypePaymentCode || !AbonentID) {
        this.$router.push({ name: 'home' });
        return;
      }

      const YYYY = new Date().getFullYear();
      const MM = `0${new Date().getMonth() + 1}`.slice(-2);

      const response = await api.performTask(this.loadAccountTask, {
        TypePaymentCode,
        AbonentID,
        Month: `${YYYY}${MM}`,
      });

      if (!response) {
        const organization = this.$store.state.organizations
          .find((org) => org.code === TypePaymentCode);
        const { accountSelectorForm } = this.$refs;
        if (!organization) {
          accountSelectorForm.$refs.select.onQueryChange();
        } else {
          accountSelectorForm.$refs.select.onChange(organization);
        }
        this.$refs.accountSelectorForm.onSubmit();
        return;
      }

      const { data: accountDetails } = response;
      this.$store.commit('accountDetails', accountDetails);
      this.$store.commit('set_selected_organization_id_by_code', TypePaymentCode);
    },

    cancel() {
      const { targetOrg } = this.$route.query;
      this.$store.commit('accountDetails', null);
      const path = { name: 'home', query: { } };
      if (targetOrg) {
        path.query = { targetOrg };
      }
      this.$router.push(path);
    },
  },

  computed: {
    status() {
      return this.loadAccountTask?.last?.status;
    },

    organizations() {
      return this.$store.state.organizations;
    },
    regions() {
      return this.$store.state.regions;
    },
    selectedOrganization() {
      return this.$store.getters.selected_org;
    },
    orgName() {
      return this.selectedOrganization.label;
    },
    address() {
      return Object.keys(this.data.Address)
        .filter((val) => this.data.Address[val].length)
        .map((val) => this.data.Address[val])
        .join(', ')
        .replace(/-/g, ' ')
        .replace(/,[,\s]*,/g, ',');
    },

    data() {
      return this.$store.state.accountDetails;
    },

    loadedOrgs() {
      return this.$store.getters.loaded_orgs;
    },

    fully_loaded() {
      return (this.data && (this.status !== 'error') && this.loadedOrgs);
    },
  },

  components: {
    Spinner,
    ChargesList,
    AccountSelectorForm,
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

h1
  margin-bottom: 16px
  @media(max-width: 767px)
    font-size: 32px

.org, .acc, .name
  opacity: .5
  line-height: 1.4

.charges-container
  margin-top: 16px

.preloader
  font-size: 30px
  text-align: center
  padding: 4em 0
  color: $color-base
  @media(max-width: 767px)
    font-size: 20px
  .spinner
    font-size: 1.3em
    margin-right: 0.3em
  span
    vertical-align: top
    padding-top: 0.15em
    display: inline-block
</style>
