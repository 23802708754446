// import partnerProviders from './partnerProviders';
// import partnerCheckAccount from './partnerCheckAccount';
import ReqCheckAbonent from './ReqCheckAbonent';
import ReqCheckAbonentInfo from './ReqCheckAbonentInfo';
import ReqCreateOrder from './ReqCreateOrder';
import ReqCreateInvoice from './ReqCreateInvoice';
import PGCreateOrder from './PGCreateOrder';
import PGGetOrderStatus from './PGGetOrderStatus';
import PGGetOrderInfo from './PGGetOrderInfo';
import ReqUpdateInvoice from './ReqUpdateInvoice';
import ReqConfirmInvoice from './ReqConfirmInvoice';
import PGRegisterQR from './PGRegisterQR';
import GetApplePaySession from './GetApplePaySession';
import MakeApplePay from './MakeApplePay';
import BPGetOrderInfo from './BPGetOrderInfo';
import GooglePayDirect from './GooglePayDirect';
import BPRegister from './BPRegister';
import RegisterReceipt from './RegisterReceipt';
import DownloadReceipt from './DownloadReceipt';
import SendPaymentEmail from './SendPaymentEmail';
import ReqCalculateFee from './ReqCalculateFee';
import APIRegions from './APIRegions';
import APIOrganizations from './APIOrganizations';

export default {
  // partnerProviders,
  // partnerCheckAccount,
  APIRegions,
  APIOrganizations,
  ReqCheckAbonent,
  ReqCreateOrder,
  ReqCreateInvoice,
  ReqUpdateInvoice,
  ReqConfirmInvoice,
  PGCreateOrder,
  PGGetOrderStatus,
  PGGetOrderInfo,
  ReqCheckAbonentInfo,
  PGRegisterQR,
  GetApplePaySession,
  MakeApplePay,
  BPGetOrderInfo,
  GooglePayDirect,
  BPRegister,
  RegisterReceipt,
  DownloadReceipt,
  SendPaymentEmail,
  ReqCalculateFee,
};
